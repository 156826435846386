import _ from 'lodash';

import centresAPI from '../api/centre';
import widgetAPI from '../api/widget';
import handleError from './handleError';

// fetchProviders
// is used in custom, minimal and bookingPage
// ? is bookingClinic and checkedVariants still used
export const fetchProviders = async (centreId) => {
  try {
    const { data: centre } = await centresAPI.getCentre(centreId);
    if (!centre) {
      return { loading: false };
    }

    const providers = _.filter(
      _.get(centre, 'CentreProvider', []),
      (provider) => provider.bookable,
    );
    const checkedVariants = [];

    const serviceVariants = _.filter(
      _.map(centre.Booking, (booking) => ({
        ...booking,
        service_id: booking.booking_id,
        _id: booking.booking_id,
      })),
      (item) => item.onlineBooking,
    );

    const bookingClinic = centre.centre_id;

    return {
      loading: false,
      centre,
      checkedVariants,
      serviceVariants,
      providers,
      bookingClinic,
    };
  } catch (e) {
    handleError.logError(e);
    return { loading: false };
  }
};

// todo not working gives a bug
// setServiceList
export const setServiceList = async (chosenProvider, serviceVariants) => {
  if (_.isEmpty(chosenProvider)) {
    return { services: [] };
  }

  const serviceProviderVariants = _.filter(serviceVariants, (service) => {
    const bookingProviders = _.get(service, 'BookingProvider', []);
    return _.some(
      bookingProviders,
      (provider) => provider.centreProvider_fk === chosenProvider.centreProvider_id,
    );
  });

  _.map(serviceProviderVariants, (service) => {
    if (service.name.includes(', pro 5 Minuten')) {
      // eslint-disable-next-line no-param-reassign,prefer-destructuring
      service.name = service.name.split(', pro 5 Minuten')[0];
    }
  });
  return { services: serviceProviderVariants };
};

// setDefaultChosenService
// ? doesnt look perfectly right...why sort if only 1 service is important
export const setDefaultChosenService = (
  preselectedServiceId,
  isManualSorting,
  prevService,
  services,
) => {
  const sortedServices = _.sortBy(services, isManualSorting ? 'order' : 'name');

  const nextService = _.find(sortedServices, (service) => {
    if (preselectedServiceId) {
      return service.service_id === preselectedServiceId;
    }
    return null;
  });

  return { chosenService: nextService };
};

export const fetchWidget = async (widgetId) => {
  try {
    const { data: widget } = await widgetAPI.getWidget(widgetId);
    if (!widget) {
      return { loading: false };
    }
    return widget;
  } catch (e) {
    handleError.logError(e);
    return { loading: false };
  }
};
// todo
// fetchProviderOffer

// todo
// bookAppointment
