/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */
import Popover from '@material-ui/core/Popover';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import Tappable from 'react-tappable';

import _ from 'lodash';
import appointmentService from '../../api/api/appointmentService';
import { DATE_FORMAT } from '../../constants/dateConstants';
import { SmallLeftArrowIcon, SmallRightArrowIcon } from '../../constants/icons';
import DateTime from '../../utils/date-time';
import CustomLoader from '../Loader';
import SVGIcon from '../SVGIcon';
import TimeSlot from '../TimeSlot/TimeSlot';
import styles from './styles';
import stylesSmall from './stylesSmall';
import { getListSlot } from '../../api/appointmentBookedSlot';

const MONTH_NAMES = [
  'label_january',
  'label_february',
  'label_march',
  'label_april',
  'label_may',
  'label_june',
  'label_july',
  'label_august',
  'label_september',
  'label_october',
  'label_november',
  'label_december',
];

const WEEK_NAMES = [
  'labelShort_sunday',
  'labelShort_monday',
  'labelShort_tuesday',
  'labelShort_wednesday',
  'labelShort_thursday',
  'labelShort_friday',
  'labelShort_saturday',
];

const TAPPABLE_STYLES = `
  .ith_calendarControlPrev, .ith_calendarControlNext {
    position: absolute;
    top: 10px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #FFF;
  }
  .ith_calendarControlPrev {
    left: 20px;
  }
  .ith_calendarControlNext {
    right: 20px;
  }
  .ith_calendarControlPrev .ith_SmallArrowIcon, .ith_calendarControlNext .ith_SmallArrowIcon {
    // color: #2A99CC !important;
    font-size: inherit !important;
  }
  .ith_calendarControlPrev .ith_SmallArrowIcon_disabled, .ith_calendarControlNext .ith_SmallArrowIcon_disabled {
    cursor: default;
  }
  #ith_monthYear {
    color: #fff !important;
    font-size: 18px !important;
  }
`;

class AppointmentTimePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMiniCalendar: false,
      selectedDay: moment().startOf('day'),
      firstAvailableDate: null,
      bookingDisable: false,
      isOfferFetching: false,
      gridDisplay: false,
      providerOffer: [],
      providerOfferFetchedFirstTime: false,
      visibleDays: this.getVisibleDays(),
      listPreBooked: [],
    };

    this.nextProviderOffer = [];
    this.firstDayOfNextWeek = '';
    this.lastDayOfNextWeek = '';

    this.onYesClick = this.onYesClick.bind(this);
    this.onNoClick = this.onNoClick.bind(this);

    this.getCurrentMonth = this.getCurrentMonth.bind(this);
    this.setDay = this.setDay.bind(this);
    this.setDayName = this.setDayName.bind(this);
    this.toggleMiniCalendar = this.toggleMiniCalendar.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.changeSelectedDay = this.changeSelectedDay.bind(this);
    this.setOfferFetching = this.setOfferFetching.bind(this);
    this.fetchProviderOffer = this.fetchProviderOffer.bind(this);
    this.getVisibleDays = this.getVisibleDays.bind(this);
  }

  async fetchPreBookedList() {
    const response = await getListSlot(this.props.providerId, this.props.serviceId);
    this.setState({ listPreBooked: response, visibleDays: this.getVisibleDays() });
  }

  componentDidMount() {
    this.fetchPreBookedList();
    window.addEventListener('resize', this.updateVisibleDays);

    if (this.props.apptDate) {
      this.setState(
        {
          selectedDay: moment(this.props.apptDate).startOf('day'),
        },
        () => this.fetchProviderOffer(),
      );
    } else {
      this.fetchProviderOffer();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.serviceId !== this.props.serviceId
        || prevProps.providerId !== this.props.providerId)
      && !this.state.isOfferFetching
      && !this.props.apptDate
    ) {
      this.nextProviderOffer = [];
      this.firstDayOfNextWeek = '';
      this.lastDayOfNextWeek = '';

      this.setState(
        {
          selectedDay: moment().startOf('day'),
          providerOfferFetchedFirstTime: false,
          providerOffer: [],
        },
        () => this.fetchProviderOffer(),
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateVisibleDays);
  }

  onYesClick() {
    this.props.onConfirmClick(this.props.appointment);
  }

  onNoClick() {
    this.props.closeModal();
  }

  fetchNextProviderOffer = async () => {
    try {
      const {
        providerId,
        centreId,
        serviceId,
        centreBookingLimits = {},
      } = this.props;
      const { selectedDay, visibleDays } = this.state;

      if (!providerId || !centreId || !serviceId) {
        return;
      }

      const params = {
        centreId,
        providerId,
      };

      const { bookingLimitMaximumValue, bookingLimitMaximumUnit } = centreBookingLimits;
      const maximumDateInAdvance = moment().add(
        bookingLimitMaximumValue,
        bookingLimitMaximumUnit,
      );

      if (
        bookingLimitMaximumValue
        && bookingLimitMaximumUnit
        && moment(this.state.selectedDay).isAfter(maximumDateInAdvance)
      ) {
        // eslint-disable-next-line consistent-return
        return null;
      }

      this.firstDayOfNextWeek = moment(this.lastDayOfNextWeek)
        .add(1, 'day')
        .format(DATE_FORMAT);
      this.lastDayOfNextWeek = moment(this.firstDayOfNextWeek)
        .add(visibleDays[1] - 1, 'day')
        .format(DATE_FORMAT);

      const nextProviderServiceOffer = await appointmentService.getProviderServiceOffer(params, {
        serviceId,
        start: this.firstDayOfNextWeek,
        end: this.lastDayOfNextWeek,
      });

      this.nextProviderOffer.push(...nextProviderServiceOffer);

      if (
        moment(this.lastDayOfNextWeek).isBefore(
          moment(selectedDay).add('2', 'month'),
        )
      ) {
        // eslint-disable-next-line consistent-return
        return this.fetchNextProviderOffer();
      }
    } catch (e) {
      console.error(e);
    }
  };

  // eslint-disable-next-line react/sort-comp
  setSelectedTimeSlots() {
    const {
      apptDate,
      apptTime: { startTime, endTime },
    } = this.props;
    const updatedProviderOffer = _.clone(this.state.providerOffer);

    const selectedDate = this.getDate(0);
    const apptAndCurrentDatesDiff = moment(apptDate, 'YYYY-MM-DD').diff(
      moment(selectedDate, 'YYYY-MM-DD'),
      'days',
    );
    const providerDayOffer = updatedProviderOffer[this.state.visibleDays[0] + apptAndCurrentDatesDiff];

    updatedProviderOffer[this.state.visibleDays[0] + apptAndCurrentDatesDiff] = _.map(providerDayOffer, (timeSlot) => {
      if (
        moment(timeSlot.startTime, 'hh:mm').isBetween(
          moment(startTime, 'hh:mm'),
          moment(endTime, 'hh:mm'),
          null,
          '[]',
        )
      ) {
        // eslint-disable-next-line no-param-reassign
        timeSlot.selected = true;
        return timeSlot;
      }
      return timeSlot;
    });

    this.setState({
      providerOffer: this.sortProviderOffer(updatedProviderOffer),
    });
  }

  getCurrentMonth() {
    const { selectedDay } = this.state;
    const { t } = this.props;
    const monthNumber = selectedDay.month();

    return t(MONTH_NAMES[monthNumber]);
  }

  setDay(delta) {
    const clonedSelectedDay = _.cloneDeep(this.state.selectedDay);

    return clonedSelectedDay.add(delta, 'days').format('D');
  }

  setDayName(delta) {
    const { selectedDay } = this.state;
    const { t } = this.props;

    const clonedSelectedDay = _.cloneDeep(selectedDay);
    const dayOfWeek = clonedSelectedDay.add(delta, 'days').day();

    return t(WEEK_NAMES[dayOfWeek]);
  }

  // eslint-disable-next-line react/sort-comp
  toggleMiniCalendar() {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      showMiniCalendar: !this.state.showMiniCalendar,
    });
  }

  // eslint-disable-next-line react/sort-comp
  handleDayClick(day) {
    const startOfDay = moment(day).startOf('day');

    this.setState(
      {
        selectedDay: startOfDay,
        showMiniCalendar: false,
      },
      () => this.fetchProviderOffer(),
    );
  }

  changeSelectedDay(delta) {
    if (delta === 0) {
      return;
    }

    const clonedSelectedDay = _.cloneDeep(this.state.selectedDay);
    const newSelectedDay = clonedSelectedDay.add(delta, 'day');

    if (this.isCalendarControlDisabled(delta)) {
      return;
    }

    const newSelectedWeekStart = moment(newSelectedDay);
    const newSelectedWeekEnd = moment(newSelectedDay).add(
      this.state.visibleDays[1] - 1,
      'day',
    );
    const selectedWeekDates = DateTime.getDaysListByStartEndMoment(
      newSelectedWeekStart,
      newSelectedWeekEnd,
    );

    const nextWeekProviderOffer = _.filter(
      this.nextProviderOffer,
      (offer) => moment(offer.date).isSameOrAfter(newSelectedWeekStart)
        && moment(offer.date).isSameOrBefore(newSelectedWeekEnd),
    );

    const sortedNextWeekProviderOffer = [];

    _.forEach(selectedWeekDates, (date, index) => {
      sortedNextWeekProviderOffer[index] = nextWeekProviderOffer.filter(
        (offer) => offer.date === date,
      );
    });

    this.setState({
      selectedDay: newSelectedDay,
      providerOffer: this.sortProviderOffer(sortedNextWeekProviderOffer),
    });

    if (
      moment(this.lastDayOfNextWeek).isBefore(
        moment(newSelectedDay).add('2', 'month'),
      )
    ) {
      this.fetchNextProviderOffer();
    }
  }

  isCalendarControlDisabled = (delta) => {
    const { centreBookingLimits = {} } = this.props;
    const { selectedDay, firstAvailableDate } = this.state;
    const { bookingLimitMaximumValue, bookingLimitMaximumUnit } = centreBookingLimits;

    const clonedSelectedDay = _.cloneDeep(selectedDay);
    const newSelectedDay = clonedSelectedDay.add(delta, 'day');
    const maximumDateInAdvance = moment().add(
      bookingLimitMaximumValue,
      bookingLimitMaximumUnit,
    );

    return (
      moment(newSelectedDay)
        .add(this.getVisibleDays()[1], 'day')
        .isSameOrBefore(moment(firstAvailableDate), 'day')
      || (bookingLimitMaximumValue
        && bookingLimitMaximumUnit
        && moment(newSelectedDay).isAfter(maximumDateInAdvance))
    );
  };

  async fetchProviderOffer() {
    this.setOfferFetching(true);
    try {
      const {
        providerId,
        centreId,
        serviceId,
        centreBookingLimits = {},
      } = this.props;

      if (!providerId || !centreId || !serviceId) {
        return;
      }

      const params = {
        centreId,
        providerId,
      };
      const { bookingLimitMaximumValue, bookingLimitMaximumUnit } = centreBookingLimits;
      const maximumDateInAdvance = moment().add(bookingLimitMaximumValue, bookingLimitMaximumUnit);

      if (
        bookingLimitMaximumValue
          && bookingLimitMaximumUnit
          && moment(this.state.selectedDay).isAfter(maximumDateInAdvance)
      ) {
        // eslint-disable-next-line consistent-return
        return null;
      }

      const providerServiceOfferRequest = _.times(
        this.state.visibleDays[1],
        (idx) => {
          const date = this.getDate(idx - this.state.visibleDays[0]);

          return appointmentService.getProviderServiceOffer(params, {
            serviceId,
            date,
          });
        },
      );

      const providerOffer = await Promise.all(providerServiceOfferRequest);

      if (!this.state.providerOfferFetchedFirstTime) {
        const firstAvailableOffer = _.find(providerOffer, (offer) => _.some(offer, (timeSlot) => timeSlot.available));

        if (firstAvailableOffer) {
          const firstAvailableDate = firstAvailableOffer[0].date;

          if (
            this.state.selectedDay.format(DATE_FORMAT) !== firstAvailableDate
          ) {
            this.setState({
              selectedDay: moment(firstAvailableDate, DATE_FORMAT),
            });
            // eslint-disable-next-line consistent-return
            return this.fetchProviderOffer();
          }
        } else {
          this.setState({
            // eslint-disable-next-line react/no-access-state-in-setstate
            selectedDay: moment(this.state.selectedDay).add(this.state.visibleDays[1], 'days')
              .startOf('day'),
          });
          // eslint-disable-next-line consistent-return
          return this.fetchProviderOffer();
        }
      }

      this.setState(
        (prevState) => (
          {
            providerOffer: this.sortProviderOffer(providerOffer),
            firstAvailableDate: prevState.selectedDay,
            isOfferFetching: false,
            providerOfferFetchedFirstTime: true,
            gridDisplay: true,
          }),
        () => {
          this.nextProviderOffer.push(
            ..._.flatMap(providerOffer, (offer) => [...offer]),
          );

          this.firstDayOfNextWeek = moment(this.state.selectedDay).format(
            DATE_FORMAT,
          );
          this.lastDayOfNextWeek = moment(this.firstDayOfNextWeek)
            .add(this.state.visibleDays[1] - 1, 'day')
            .format(DATE_FORMAT);

          this.fetchNextProviderOffer();
          if (
            this.props.apptDate
                && this.props.apptTime.startTime
                && this.props.apptTime.endTime
          ) {
            this.setSelectedTimeSlots();
          }
        },
      );
    } catch (e) {
      console.log(e);
      console.error(e);
      this.setOfferFetching(false);
    }
  }

  getVisibleDays() {
    // first number for Delta second number for how many days
    const wrapperElement = document.getElementById('booking_container_wrapper');
    const outerWidth = this.props.isEmbed
      ? wrapperElement && wrapperElement.offsetWidth
      : window.innerWidth;

    if (this.props.isSmallPicker || outerWidth <= 650) {
      return [0, 3];
    } if (outerWidth <= 1150) {
      return [0, 5];
    }
    return [0, 7];
  }

  getDate(delta) {
    return moment(this.state.selectedDay)
      .add(delta, 'days')
      .format(DATE_FORMAT);
  }

  setOfferFetching(value = false) {
    this.setState({ isOfferFetching: value });
  }

  // eslint-disable-next-line class-methods-use-this
  sortProviderOffer(providerOffer) {
    return _.map(providerOffer, (offer) => _.sortBy(offer, (o) => o.startTime));
  }

  // eslint-disable-next-line class-methods-use-this
  checkAvailable(data, listPreBooked) {
    const processData = _.map(data, (item) => {
      const startTime = moment(`${item.date} ${item.startTime}`, 'YYYY-MM-DD HH:mm').add(1, 'minute');
      const isConflict = _.some(listPreBooked, (preBooked) => {
        const startBooked = moment(`${preBooked.date} ${preBooked.startTime}`, 'YYYY-MM-DD HH:mm');
        const endBooked = moment(`${preBooked.date} ${preBooked.endTime}`, 'YYYY-MM-DD HH:mm');
        return startTime.isBetween(startBooked, endBooked);
      });
      return {
        ...item,
        booked: !!isConflict,
      };
    });
    return processData;
  }

  render() {
    const { isEmbed, isSmallTemplate } = this.props;
    const { providerOfferFetchedFirstTime, listPreBooked } = this.state;

    const anchorElement = document.getElementById('ith_dateBar');
    const bookingContainer = document.getElementById(
      'booking_container_wrapper',
    );

    if (this.state.providerOfferFetchedFirstTime && this.state.providerOffer.length === 0) {
      return (
        <ith-div class="ith_noTimeSlots">
          Es sind keine Termine verfügbar.
        </ith-div>
      );
    }

    return (
      <ith-div class="ith_appointmentTimePickerWrapper">
        {!isEmbed && (
          <CustomLoader isLoading={!providerOfferFetchedFirstTime} />
        )}
        <ith-div class="ith_dateBar" id="ith_dateBar">
          {isSmallTemplate ? <style>{TAPPABLE_STYLES}</style> : null}
          <ith-div class="ith_monthYearBar">
            <ith-div class="ith_chooseMonthYearDiv">
              <Popover
                id="monthYear"
                onClose={this.toggleMiniCalendar}
                open={this.state.showMiniCalendar}
                anchorEl={anchorElement}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                <ith-div class="ith_day_picker">
                  <DayPicker
                    selectedDays={new Date()}
                    onDayClick={this.handleDayClick}
                  />
                </ith-div>
              </Popover>
              <Tappable onTap={this.toggleMiniCalendar}>
                <ith-p id="ith_monthYear">
                  {this.getCurrentMonth()}
                  {' '}
                  {this.state.selectedDay.year()}
                </ith-p>
              </Tappable>
            </ith-div>
          </ith-div>

          <ith-div
            class="ith_dayCirclesBar"
            style={
              isEmbed
              && isSmallTemplate
              && bookingContainer
              && this.state.gridDisplay
                ? {
                  display: 'grid',
                  gridTemplateColumns: `repeat(${this.state.visibleDays[1]}, 1fr)`,
                }
                : {}
            }
          >
            <Tappable
              className="ith_calendarControlPrev"
              onTap={() => this.changeSelectedDay(-this.state.visibleDays[1])}
            >
              <ith-div
                class={`
                  ith_SmallArrowIcon
                  ${this.isCalendarControlDisabled(-this.state.visibleDays[1]) ? 'ith_SmallArrowIcon_disabled' : ''}
                `}
              >
                <SVGIcon icon={SmallLeftArrowIcon} />
              </ith-div>
            </Tappable>
            {(isEmbed && bookingContainer)
            || (!isEmbed && this.state.selectedDay)
              ? _.times(this.state.visibleDays[1], (idx) => {
                const delta = idx - this.state.visibleDays[0];
                return (
                  <ith-div class="ith_dayDiv" key={idx}>
                    <ith-div
                      class={`
                        ${isSmallTemplate ? 'ith_dayDivSmall' : ''}
                        ${isSmallTemplate && this.setDay(delta) === moment().format('D') ? 'ith_dayDivSmallSelected' : ''}
                      `}
                    >
                      <ith-div class="ith_dayName">
                        {this.setDayName(delta)}
                      </ith-div>
                      <ith-button
                        class={`ith_dayCircle ${isSmallTemplate ? '' : 'ith_hoverElements'}`}
                        onClick={() => this.changeSelectedDay(delta)}
                      >
                        <ith-p class="ith_day">{this.setDay(delta)}</ith-p>
                      </ith-button>
                    </ith-div>
                  </ith-div>
                );
              })
              : null}
            <Tappable
              className="ith_calendarControlNext"
              onTap={() => this.changeSelectedDay(+this.state.visibleDays[1])}
            >
              <ith-div
                class={`
                  ith_SmallArrowIcon
                  ${this.isCalendarControlDisabled(+this.state.visibleDays[1]) ? 'ith_SmallArrowIcon_disabled' : ''}
                `}
              >
                <SVGIcon icon={SmallRightArrowIcon} />
              </ith-div>
            </Tappable>
          </ith-div>
        </ith-div>

        <ith-div class="ith_bookingCalendar">
          <ith-div
            class="ith_timeList"
            style={
              isEmbed
              && isSmallTemplate
              && bookingContainer
              && this.state.gridDisplay
                ? {
                  display: 'grid',
                  gridTemplateColumns: `repeat(${this.state.visibleDays[1]}, 1fr)`,
                }
                : {}
            }
          >
            {(isEmbed && bookingContainer)
            || (!isEmbed && this.state.providerOffer && listPreBooked)
              ? _.times(this.state.visibleDays[1], (idx) => {
                const dateProp = this.getDate(idx - this.state.visibleDays[0]);
                const getDateData = _.find(this.state.providerOffer, (providerOffer) => _.some(providerOffer, (offer) => offer.date === dateProp));
                const dataProp = this.checkAvailable(getDateData, listPreBooked);
                return (
                  <TimeSlot
                    key={idx}
                    date={dateProp}
                    data={dataProp}
                    handler={(timeSlot, date) => this.props.handler(timeSlot, date)}
                    t={this.props.t}
                    bookingDisable={this.state.bookingDisable}
                    isHiddenDisabledTimeSlot
                    isSmallPicker={this.props.isSmallPicker}
                    highlightedTimeSlot={this.props.highlightedTimeSlot}
                    isSmallTemplate={isSmallTemplate}
                    centreBookingLimits={this.props.centreBookingLimits}
                  />
                );
              })
              : null}
          </ith-div>
        </ith-div>

        <style>{styles}</style>
        {isSmallTemplate ? <style>{stylesSmall}</style> : null}
      </ith-div>
    );
  }
}

AppointmentTimePicker.propTypes = {
  t: PropTypes.func.isRequired,
  providerId: PropTypes.string.isRequired,
  centreId: PropTypes.string.isRequired,
  serviceId: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
};

export default AppointmentTimePicker;
