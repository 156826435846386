import { publicCommonClient } from '../../../interfaces/clients';

export const getListSlot = async (providerId, bookingId) => {
  const { data } = await publicCommonClient.get(
    '/api/appointment-booked-slots',
    { params: { providerId, bookingId } },
  );
  return data;
};

export const createSlot = async (data) => {
  const response = await publicCommonClient.post(
    '/api/appointment-booked-slots',
    data,
  );
  return response;
};

export const deleteSlot = async (id) => {
  const response = await publicCommonClient.delete(
    `/api/appointment-booked-slots/${id}`,
  );
  return response;
};

export default {
  getListSlot,
  createSlot,
  deleteSlot,
};
