/* eslint-disable linebreak-style */
import { commonClient, publicBookingClient } from '../clients';

const bookingInterface = {
  getOffers: async (centreId, providerId, serviceId, dateStart, dateEnd) => {
    const { data: offers } = await publicBookingClient.get(
      `/providers/${providerId}/centres/${centreId}/services-day-offer?start=${dateStart}&end=${dateEnd}`,
    );

    return offers;
  },
  bookAppointment: async (
    centreId,
    providerId,
    serviceId,
    userId,
    date,
    startTime,
    endTime,
  ) => {
    const { data: offers } = await commonClient.post('/appointments', {
      centreId,
      dirPartnerId: centreId,
      providerId,
      booking_fk: serviceId,
      consumerId: userId,
      createdByUserId: userId,
      date,
      startTime,
      endTime,
      description: '',
      status: 'CONFIRMED',
    });

    return offers;
  },
};

export default bookingInterface;
