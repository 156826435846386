/* eslint-disable func-names */
/* eslint-disable class-methods-use-this */
import { Col, Row, Tabs } from 'antd';
import React, { PureComponent } from 'react';

import StoreConnector from '../../hoc/StoreConnector';
import withParams from '../../hoc/withParams';
import {
  getInvoiceThunk,
  markInvoiceAsViewedThunk,
  resetInvoiceThunk,
} from '../../redux/thunks/dashboard';
import stateUtils from '../../utils/state';
import './styles.css';

class InvoiceView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pdfList: [],
    };
  }

  componentDidMount() {
    this.reflectProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.reflectProps(nextProps);
  }

  componentWillUnmount() {
    this.props.dispatch(resetInvoiceThunk());
  }

  reflectProps(props) {
    if (
      !props.isFetchingInvoice
      && !props.isValidInvoice
      && !props.isFailedFetchingInvoice
    ) {
      props.dispatch(getInvoiceThunk(props.params.invoiceId));
      props.dispatch(markInvoiceAsViewedThunk(props.params.invoiceId));
    }
    if (props.isValidInvoice) {
      // not needed at the moment maybe needed in the future
      // this.setState({ invoice: props.invoice }, () => this.setState({ invoice: props.invoice }));

      // adding PDFs to show patient (in future possible to change from 1-3 diffrent PDFs)
      this.setState({
        pdfList: [
          {
            key: '1',
            title: 'pdfWithLogoTempLink',
            src: props.invoice.pdfWithLogoTempLink,
            label: 'Rechnung',
          },
        ],
      });
      if (props.invoice.pdfWoLogoTempLink) {
        this.setState((prevState) => ({
          pdfList: [
            ...prevState.pdfList,
            {
              key: '2',
              title: 'pdfWoLogoTempLink',
              src: props.invoice.pdfWoLogoTempLink,
              label: 'Rückforderungsbeleg',
            },
          ],
        }));
      }
    }
  }

  render() {
    // eslint-disable-next-line func-names
    const isSafari = /constructor/i.test(window.HTMLElement)
      || (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
      }(
        !window.safari
          || (typeof safari !== 'undefined' && window.safari.pushNotification),
      ));

    if (isSafari) {
      return (
        <div className="appointmentList">
          <Row align="top" justify="space-between" style={{ height: '100%' }}>
            <Col span={24} style={{ height: '100%' }}>
              <div className="safariMessage">
                Die Dokument-Vorschau ist für Safari vorübergehend deaktiviert.
                Bitte laden Sie die Rechnung über die Rechnungsliste herunter.
              </div>
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <div className="appointmentList">
        <Row align="top" justify="space-between" style={{ height: '100%' }}>
          <Col span={24} style={{ height: '100%' }}>
            <Tabs type="card">
              {this.state.pdfList.map((pdf) => (
                <Tabs.TabPane tab={pdf.label} key={pdf.key}>
                  <embed
                    title={pdf.title}
                    className="embeddedPDFInvoice"
                    src={pdf.src}
                    type="application/pdf"
                    height={800}
                    style={{ width: '100%' }}
                  />
                </Tabs.TabPane>
              ))}
            </Tabs>
          </Col>
        </Row>
      </div>
    );
  }
}

export default StoreConnector(
  withParams(InvoiceView),
  stateUtils.fullStateMap,
  {},
);
