import { Flex } from 'antd';
import moment from 'moment';
import React, {
  memo, useEffect, useRef, useState,
} from 'react';

let interval = null;

function CountDown({ exipredTime, onEnd }) {
  const [hour, setHour] = useState('00');
  const [minute, setMinute] = useState('00');
  const [second, setSecond] = useState('00');
  const remainTime = useRef(moment(exipredTime).diff(moment(), 'second'));
  useEffect(() => {
    if (!interval) {
      interval = setInterval(() => {
        remainTime.current -= 1;
        let minutes = parseInt(remainTime.current / 60, 10);
        let seconds = parseInt(remainTime.current % 60, 10);
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;
        setHour('00');
        setMinute(minutes);
        setSecond(seconds);
        if (remainTime.current <= 1) {
          onEnd();
          clearInterval(interval);
        }
      }, 1000);
    }
    return () => {
      clearInterval(interval);
      interval = null;
    };
  }, [remainTime]);
  return (
    <Flex vertical style={{ color: '#ff6d70', fontWeight: 'bold' }}>
      <div>Reserved for:</div>
      <div style={{ fontSize: '16px' }}>
        {hour}
        :
        {minute}
        :
        {second}
      </div>
    </Flex>
  );
}

export default memo(CountDown);
