export default `
  .ith_appointmentTimePickerWrapper {
    max-width: 650px;
    width: 100%;
  }
  .ith_appointmentTimePickerWrapper .ith_dayDiv {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-left: 1px solid #2A99CC;
  }
  
  .ith_appointmentTimePickerWrapper .ith_dayDivSmall {
    border-radius: 23px;
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .ith_appointmentTimePickerWrapper .ith_dayDivSmall:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2);
  }
  .ith_appointmentTimePickerWrapper .ith_dayDivSmallSelected {
    border: 1px solid #2A99CC;
  }

  .ith_appointmentTimePickerWrapper .ith_dayDiv:nth-child(2) {
    border: none !important;
  }
  
  .ith_appointmentTimePickerWrapper .ith_dateBar {
    position: relative;
    margin: 0;
    padding: 0;
    box-shadow: none;
    border-radius: 20px 20px 0 0;
    border: none;
    background: #2A99CC;
  }
  
  .ith_appointmentTimePickerWrapper .ith_dayCirclesBar {
    margin-bottom: 0;
    border-radius: 20px 20px 0 0;
    background: #fff;
    border-right: 1px solid #2A99CC;
    border-left: 1px solid #2A99CC;
    padding-bottom: 0;
  }
  
  .ith_appointmentTimePickerWrapper .ith_dayCircle {
    background: transparent;
  }
  
  .ith_appointmentTimePickerWrapper .ith_monthYearBar {
    margin-bottom: 0;
  }
  
  .ith_appointmentTimePickerWrapper .ith_bookingCalendar {
    height: 400px;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid #2A99CC;
    border-radius: 0 0 20px 20px;
  }
  
  .ith_appointmentTimePickerWrapper .ith_timeList {
    padding: 0;
  }
  
  @media (max-width: 767px) {
    .ith_appointmentTimePickerWrapper {
      width: 100%;
    }
  }
`;
