import * as _ from 'lodash';
import * as moment from 'moment';
import React, { PureComponent } from 'react';

import { DATE_FORMAT, TIME_FORMAT } from '../../constants/dateConstants';
import DateTime from '../../utils/date-time';
import styles from './styles';
import stylesSmall from './stylesSmall';

class TimeListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      timeFormat: '',
    };
  }

  componentDidMount() {
    const timeFormat = DateTime.getTimeFormatByLocale();
    this.setState({ timeFormat });
  }

  onBookClick = (timeSlot) => {
    const { available } = timeSlot;
    const { bookingDisable, handler, date } = this.props;
    if (bookingDisable || !available) {
      return;
    }

    handler(timeSlot, date);
  };

  render() {
    const {
      data,
      date,
      t,
      isHiddenDisabledTimeSlot,
      isSmallPicker,
      highlightedTimeSlot,
      isSmallTemplate,
    } = this.props;
    const { timeFormat } = this.state;

    const centreBookingLimits = this.props.centreBookingLimits || {};
    const {
      limitMinimumValue,
      limitMinimumUnit,
      bookingLimitMaximumValue,
      bookingLimitMaximumUnit,
    } = centreBookingLimits;

    const firstTimeSlotOfTheDay = !_.isEmpty(data) && _.head(data);
    const lastTimeSlotOfTheDay = !_.isEmpty(data) && _.last(data);

    const momentStartDateMinimum = moment(
      `${date} ${lastTimeSlotOfTheDay.startTime}`,
      `${DATE_FORMAT} ${TIME_FORMAT}`,
    );
    const momentStartDateMaximum = moment(
      `${date} ${firstTimeSlotOfTheDay.startTime}`,
      `${DATE_FORMAT} ${TIME_FORMAT}`,
    );
    const minimumDateInAdvance = moment().add(
      limitMinimumValue,
      limitMinimumUnit,
    );
    const maximumDateInAdvance = moment().add(
      bookingLimitMaximumValue,
      bookingLimitMaximumUnit,
    );

    if (
      (bookingLimitMaximumValue
        && bookingLimitMaximumUnit
        && momentStartDateMaximum > maximumDateInAdvance)
      || (limitMinimumValue
        && limitMinimumUnit
        && momentStartDateMinimum < minimumDateInAdvance)
    ) {
      return (
        <ith-div class="ith_emptySlot">
          <p className="ith_noTimeSlots">{t('label_outsideOfBookingPeriod')}</p>

          <style>{styles}</style>
          {isSmallTemplate ? <style>{stylesSmall}</style> : null}
        </ith-div>
      );
    }

    if (_.isEmpty(data)) {
      return (
        <ith-div class="ith_emptySlot">
          <style>{styles}</style>
          {isSmallTemplate ? <style>{stylesSmall}</style> : null}
        </ith-div>
      );
    }

    return (
      <ith-div class="ith_timeSlot">
        {this.props.data.map((slot, i) => {
          if (!slot.available && !slot.selected && isHiddenDisabledTimeSlot) return;

          let isHighlighted;
          if (
            highlightedTimeSlot
            && highlightedTimeSlot.timeSlot
            && highlightedTimeSlot.date
            && slot.startTime === highlightedTimeSlot.timeSlot.startTime
            && slot.date === highlightedTimeSlot.date
          ) {
            isHighlighted = true;
          }

          // eslint-disable-next-line consistent-return
          return (
            <ith-div
              class={`ith_timeBox ${slot.available && !slot.booked ? 'ith_available' : ''} ${slot.selected ? 'ith_selected' : ''} ${isSmallPicker ? 'ith_smallPicker' : ''} ${isHighlighted ? 'ith_highlighted' : ''}`}
              key={`timebox-${i + 1}`}
              onClick={() => (slot.available && !slot.booked) && this.onBookClick(slot)}
            >
              {moment(slot.startTime, TIME_FORMAT).format(timeFormat)}
            </ith-div>
          );
        })}

        <style>{styles}</style>
        {isSmallTemplate ? <style>{stylesSmall}</style> : null}
      </ith-div>
    );
  }
}

export default TimeListItem;
