import { Button, Col, Row } from 'antd';
import React, { useCallback } from 'react';

import _ from 'lodash';
import APPOINT_STATUSES from '../../constants/appointmentStatuses';
import appointmentService from '../../api/api/appointmentService';
import { EMBED_STEPS } from '../../constants/authModalTypes';
import styles from './styles';

function EmbedConfirmation(props) {
  const {
    t,
    centre,
    provider,
    services,
    startTime,
    endTime,
    date,
    userData,
    handleStepChange,
    errorHandler,
  } = props;

  const onBookClick = useCallback(_.debounce(async () => {
    try {
      const data = {
        providerId: provider.centreProvider_id,
        booking_fk: services[0].service_id,
        date,
        startTime,
        endTime,
        status: APPOINT_STATUSES.CONFIRMED,
        centreId: centre.centre_id,
        description: '',
        consumerId: userData.user_id,
        createdByUserId: userData.user_id,
        dirPartnerId: centre.centre_id,
      };

      await appointmentService.createAppointment(data);

      handleStepChange(EMBED_STEPS.SUCCESS);
    } catch (e) {
      errorHandler(e);
    }
  }, 500), []);

  return (
    <ith-div class="ith_auth-wrapper-field">
      <Row justify="center">
        <Col xs={24} md={6}>
          <Button
            className="btn--auth btn--auth-primary ith_bookButton"
            onClick={onBookClick}
          >
            {t('label_confirm')}
          </Button>
        </Col>
      </Row>

      <style>{styles}</style>
    </ith-div>
  );
}

export default EmbedConfirmation;
