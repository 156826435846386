import {
  Button,
  Col, Flex, Modal, Row, Typography,
} from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { Icon } from 'react-icons-kit';
import { pencil } from 'react-icons-kit/fa';
import env from '../../../../config/env';
import { DATE_FORMAT, TIME_FORMAT } from '../../constants/dateConstants';
import Countdown from '../Countdown';
import styles from './styles';
import { deleteSlot } from '../../api/appointmentBookedSlot';

const getProviderAddress = (address) => {
  if (address) {
    const street = get(address, 'street', '');
    const building = get(address, 'building', '');
    const zipCode = get(address, 'zipcode', '');
    const city = get(address, 'city', '');
    const comma = street || building ? ',' : '';

    return `${street} ${building}${comma} ${zipCode} ${city}`;
  }
  return '';
};

function EmbedBookingSummary({
  t,
  centre,
  showBookingCalendar,
  provider,
  services,
  startTime,
  endTime,
  date,
  slotInfomation,
}) {
  const exipredTime = moment(slotInfomation.createdAt).add(2, 'minute');
  const [openModal, setOpenModal] = useState(false);
  const renderCountDown = useMemo(() => {
    if (exipredTime) {
      return <Countdown exipredTime={exipredTime} onEnd={() => setOpenModal(true)} />;
    }
    return null;
  }, [exipredTime]);

  const onTimeOut = useCallback(() => {
    deleteSlot(slotInfomation.appointmentBookedSlot_id);
    showBookingCalendar();
  }, [slotInfomation]);

  return (
    <>
      <div className="ith_embed-booking-summary">
        <div className="ith_embed-booking-summary-header">
          <div>{t('L.BookingSummary')}</div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className="ith_embed-booking-summary-header-button"
            onClick={onTimeOut}
          >
            <Icon icon={pencil} />
          </div>
        </div>
        <div className="ith_embed-booking-summary-main">
          <Row gutter={[0, 8]}>
            <Col span={22} offset={1}>
              <Flex align="center" justify="space-between" style={{ width: '100%' }}>
                <Flex align="center">
                  <img
                    src={`${env.api.consumerApps}/imgs/embed/provider.png`}
                    alt=""
                  />
                  <Typography.Text
                    style={{ fontSize: '16px', fontWeight: 500 }}
                  >
                    {`${provider.User.firstName} ${provider.User.lastName}`}
                  </Typography.Text>
                </Flex>
                {renderCountDown}
              </Flex>
            </Col>
            <Col span={22} offset={1}>
              <img
                src={`${env.api.consumerApps}/imgs/embed/location.png`}
                alt=""
              />
              <Typography.Text style={{ fontSize: '16px', fontWeight: 500 }}>
                {`${centre.name} ${getProviderAddress(provider.address)}`}
              </Typography.Text>
            </Col>
            <Col span={22} offset={1}>
              <img
                src={`${env.api.consumerApps}/imgs/embed/service.png`}
                alt=""
              />
              <Typography.Text style={{ fontSize: '16px', fontWeight: 500 }}>
                {`${get(services, '[0].name', '')}, ${get(services, '[0].estimatedPrice', '')}`}
              </Typography.Text>
            </Col>
            <Col span={22} offset={1}>
              <img
                src={`${env.api.consumerApps}/imgs/embed/datetime.png`}
                alt=""
              />
              <Typography.Text style={{ fontSize: '16px', fontWeight: 500 }}>
                {moment(
                  `${date} ${startTime}`,
                  `${DATE_FORMAT} ${TIME_FORMAT}`,
                ).format('DD.MM.YYYY - HH:mm')}
                {' '}
                -
                {' '}
                {endTime}
              </Typography.Text>
            </Col>
          </Row>
        </div>
      </div>
      <Modal open={openModal} title="Timeout" onCancel={onTimeOut} destroyOnClose footer={<Button onClick={onTimeOut}>OK</Button>}>
        <div>You ran out of time, please choose another time slot</div>
      </Modal>
      <style>{styles}</style>
    </>
  );
}

export default EmbedBookingSummary;
