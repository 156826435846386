export default `
  #booking_container_wrapper div {
    box-sizing: border-box;
  }
  .ith_booking_container .ith_content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ith_booking_container .ith_bookButton {
    max-width: 650px;
    width: 100%;
    border-radius: 37px;
  }
  .ith_booking_container .ith_employeeService {
    max-width: 650px;
    width: 100%;
    margin: 0;
  }
  .ith_booking_container .ith_employeeDiv {
    max-width: 650px;
    width: 100%;
    margin: 20px 0;
  }
  .ith_booking_container .ith_serviceDiv {
    max-width: 650px;
    width: 100%;
    margin-bottom: 20px;
  }
  .ith_booking_container .ith_service {
    padding-left: 28px;
    padding-right: 22px;
    border: 1px solid #2A99CC;
  }
  .ith_booking_container .ith_employee {
    padding-right: 22px;
    border: 1px solid #2A99CC;
  }
  .ith_booking_container .ith_employee_disabled {
    cursor: default;
  }
  .ith_booking_container .ith_employee, .ith_booking_container .ith_service {
    border-radius: 30px;
  }
  .ith_dropDownService {
    display: flex;
    align-items: start;
    justify-content: space-between;
    font-family: Roboto;
    font-weight: 500;
    color: #273d52;
    height: 40px;
    width: 100%;
  }
  .ith_dropdownText {
    font-family: Roboto;
    font-weight: 500;
  }
  .ith_dropdownPrice {
    font-family: Roboto;
  }
  
  p#ith_therapyName {
    font-size: 14px;
    margin: 0;
  }
  
  p#ith_therapyPrice {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  
  @media (max-width: 767px) {
    .ith_booking_container .ith_employeeService,
    .ith_booking_container .ith_bookButton,
    .ith_booking_container .ith_employeeDiv,
    .ith_booking_container .ith_serviceDiv {
      width: 100%;
    }
    
  }
`;
